<template>
  <span v-if="value !== null">
    {{value}} ms
  </span>
</template>

<script>
import Column from '@/pages/search/controllers/column'

export default {
  inheritAttrs: false,
  components: {
  },
  props: {
    value: {
      type: Number
    },
    column: Column
  }
}
</script>
